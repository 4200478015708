import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { Container, Card, CardContent, CardHeader, Grid, Typography, Button, CircularProgress, Paper } from '@mui/material';
import { SubscriptionResponse } from '../types/types';
import { getPortalUrl } from '../services/StripeService';

interface ManagedSubscriptionProps {
    subscription: SubscriptionResponse;
}

const ManageSubscription: React.FC<ManagedSubscriptionProps> = ({ subscription }) => {
    const authContext = useContext(AuthContext);
    const { app } = authContext || {};
    const [isLoadingManageSubscription, setIsLoadingManageSubscription] = useState(false);

    const { device_count, device_limit, subscription_data } = subscription;

    if (!subscription_data) return null;

    const { plan, status, current_period_start, current_period_end, cancel_at_period_end } = subscription_data;

    const periodStartFormatted = new Date(current_period_start * 1000).toLocaleDateString();
    const periodEndFormatted = new Date(current_period_end * 1000).toLocaleDateString();
    const daysLeft = Math.ceil((current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24));

    const manageSubscription = async () => {
        if (!app) return;
        setIsLoadingManageSubscription(true);
        // console.log('Manage Subscription');
        const portalUrl = await getPortalUrl(app);
        window.location.href = portalUrl;
    };

    return (
        <>
            <Container>
                <Card>
                    <CardHeader title={`Subscription Plan: ${plan}`} subheader={`Devices: ${device_count} / ${device_limit}`} />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Current Period: {periodStartFormatted} - {periodEndFormatted}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Status: {status.toUpperCase()}
                                </Typography>
                            </Grid>
                            {cancel_at_period_end && (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="error" component="p">
                                        This subscription will be cancelled at the end of the current period.
                                    </Typography>
                                </Grid>
                            )}
                            {!cancel_at_period_end && (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Your subscription will be renewed automatically in {daysLeft} days
                                    </Typography>
                                </Grid>
                            )}
                            {!subscription.is_trial && (
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={manageSubscription}
                                        disabled={isLoadingManageSubscription}
                                        startIcon={isLoadingManageSubscription ? <CircularProgress size={20} color="primary" /> : null}
                                    >
                                        Manage Subscription
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
            </>
    );
};

export default ManageSubscription;
