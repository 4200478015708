import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress, Box, Typography, Container, Card, CardContent, Grid, Alert } from '@mui/material';
import SignInWithApple from './SignInWithApple';
import SignInWithGoogle from './SignInWithGoogle';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:visited': {
        color: theme.palette.primary.main,
    },
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const SignUp: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { loading, user } = authContext || {};
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');

    // 🚀 **Add Logo Animation Logic**
    const logoImages = [
        '/icons/icon_000.png',
        '/icons/icon_010.png',
        '/icons/icon_020.png',
        '/icons/icon_030.png',
        '/icons/icon_040.png',
        '/icons/icon_050.png',
        '/icons/icon_060.png',
        '/icons/icon_070.png',
        '/icons/icon_080.png',
        '/icons/icon_090.png',
        '/icons/icon_100.png',
        '/icons/icon_110.png',
        '/icons/icon_120.png',
        '/icons/icon_130.png',
        '/icons/icon_140.png',
        '/icons/icon_150.png',
        '/icons/icon_160.png',
        '/icons/icon_170.png',
        '/icons/icon_180.png',
        '/icons/icon_190.png',
        '/icons/icon_200.png',
        '/icons/icon_210.png',
        '/icons/icon_220.png',
        '/icons/icon_230.png',
        '/icons/icon_240.png',
        '/icons/icon_250.png',
        '/icons/icon_260.png',
        '/icons/icon_270.png',
        '/icons/icon_280.png',
        '/icons/icon_290.png',
    ];
    const [logoIndex, setLogoIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setLogoIndex((prevIndex) => (prevIndex + 1) % logoImages.length);
        }, 1000); // Change logo every second

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (user) {
        navigate('/');
        return null;
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                        <Typography component="h1" variant="h5" align="center" sx={{ fontWeight: 'bold' }} color="primary">
                            Sign Up
                        </Typography>
                    </Grid> */}

                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                                {error}
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" mb={2}>
                                        <img src={logoImages[logoIndex]} alt="SonaScript Logo" width={300} />
                                    </Box>
                                </Grid>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12}>
                                        <SignInWithGoogle />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SignInWithApple />
                                    </Grid>
                                </Grid>
                                <Box height={16} />
                                <Typography variant="caption" align="center" sx={{ display: 'block', textAlign: 'center' }} color="textSecondary">
                                    By proceeding, you agree to our
                                    <br />
                                    <StyledLink to="/terms" target="_blank" rel="noopener noreferrer">
                                        Terms of Service
                                    </StyledLink>
                                    {' and '}
                                    <StyledLink to="/privacy" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </StyledLink>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default SignUp;
