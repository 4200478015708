import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, Stack, useTheme, useMediaQuery } from '@mui/material';

const Footer: React.FC = () => {
    const [isSticky, setIsSticky] = useState(true);
    const theme = useTheme();
    const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        const checkContentHeight = () => {
            const windowHeight = window.innerHeight;
            const bodyHeight = document.body.offsetHeight;
            const footerHeight = 56; // Approximate footer height

            // If content + footer is less than window height, make footer sticky
            setIsSticky(bodyHeight - footerHeight < windowHeight);
        };

        // Check initially and on window resize
        checkContentHeight();
        window.addEventListener('resize', checkContentHeight);

        // Cleanup
        return () => window.removeEventListener('resize', checkContentHeight);
    }, []);

    return (
        <Box
            component="footer"
            sx={{
                position: isSticky ? 'fixed' : 'static',
                bottom: isSticky ? 0 : 'auto',
                left: 0,
                right: 0,
                py: 2,
                backgroundColor: 'background.paper',
                borderTop: '1px solid',
                borderColor: 'divider',
                zIndex: 1000,
                mt: isSticky ? 0 : 'auto',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // maxWidth: '2000px',
                    mx: 'auto',
                    px: 3,
                }}
            >
                <Typography variant="body2" color="text.secondary">
                    <Box component="span" sx={{ fontSize: '1.5rem', mr: 0.5 }}>
                        ©
                    </Box>
                    {new Date().getFullYear()} SonaScript
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        position: isMediumUp ? 'absolute' : 'static',
                        left: '50%',
                        transform: isMediumUp ? 'translateX(-50%)' : 'none',
                        width: 'auto',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Link component={RouterLink} to="/terms" color="inherit">Terms of Service</Link>
                    {' • '}
                    <Link component={RouterLink} to="/privacy" color="inherit">Privacy Policy</Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;
