import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';
import SignInButton from './SignInButton';
import GoogleIcon from '@mui/icons-material/Google';

const SignInWithGoogle: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { signInWithGoogle } = authContext || {};

    return <SignInButton label="Sign-In with Google" onClick={signInWithGoogle!} icon={<GoogleIcon />} />;
};

export default SignInWithGoogle;
