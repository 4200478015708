import React from 'react';
import { Button } from '@mui/material';

interface SignInButtonProps {
    label: string;
    onClick: () => void;
    icon: React.ReactNode;
}

const SignInButton: React.FC<SignInButtonProps> = ({ label, onClick, icon }) => {
    return (
        <Button startIcon={icon} onClick={onClick} variant="outlined" fullWidth>
            {label}
        </Button>
    );
};

export default SignInButton;
