import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { Container, Grid, Paper, Skeleton, Card, CardContent, CardHeader, Button, Typography } from '@mui/material';
import SubscriptionCard from './SubscriptionCard';
import { Link as RouterLink } from 'react-router-dom';
import { SubscriptionResponse } from '../types/types';

const SubscriptionsList: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { user, getSubscriptionStatus, app } = authContext || {};
    const [subscription, setSubscription] = React.useState<SubscriptionResponse | null>(null);
    const [loadingSubscription, setLoadingSubscription] = React.useState(false);

    const planOptions = [
        {
            id: 0,
            name: 'Coffee Subscription',
            price: '$4.99/month',
            description:
                "Get all features and keep us buzzing with our coffee subscription. With this plan, SonaScript will be available for you 24/7. You're brew-tiful!",
            priceId: 'price_1Q78bpI50WvmQmIHdZtuebVq',
        },
        {
            id: 1,
            name: 'Continue with Trial',
            price: '$0.00/month',
            description:
                "SonaScript offers a full-featured trial with no expiration date. However, without its morning coffee, SonaScript can only work for 2 hours before needing to rest. It might be a bit grumpy if you ask it to work without its morning coffee. If you're okay with that, feel free to continue without a subscription. (You still need an account, though!) Otherwise, consider subscribing to support SonaScript and keep it alert whenever you need it.",
        },
    ];

    useEffect(() => {
        if (user && getSubscriptionStatus && app) {
            setLoadingSubscription(true);
            getSubscriptionStatus(app).then((subscriptionData) => {
                // console.log('subscription SubscriptionsList:');
                // console.log(subscriptionData);
                setSubscription(subscriptionData);
                setLoadingSubscription(false);
            });
        }else{
            setLoadingSubscription(false);
            setSubscription(null);
        }
    }, [user, getSubscriptionStatus, app]);

    return (
        <>
            <Paper elevation={3} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column' }}>
                <Container>
                    {loadingSubscription ? (
                        <Grid container spacing={4} justifyContent="center">
                            {[0, 1].map((item) => (
                                <Grid item xs={12} sm={6} key={item}>
                                    <Skeleton variant="rectangular" height={200} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : user && subscription && subscription.subscription_data && subscription.subscription_data.status === 'active' && !subscription.is_trial ? (
                        <Container>
                            <Typography variant="h4" component="h2" gutterBottom align="center">
                                Your Subscription
                            </Typography>
                            <Grid container justifyContent="center" textAlign={'center'}>
                                <Card>
                                    <CardHeader
                                        title={`Subscription Plan: ${subscription.subscription_data.plan}`}
                                        subheader={`Devices: ${subscription.device_count} / ${subscription.device_limit}`}
                                    />
                                    <CardContent>
                                        <Button component={RouterLink} to="/profile" sx={{ mr: 2 }}>
                                            Go to Profile
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Container>
                    ) : (
                        <Container>
                            <Typography variant="h4" component="h2" gutterBottom align="center">
                                Choose Your Plan
                            </Typography>
                            <Grid container spacing={4} justifyContent="center">
                                {planOptions.map((plan) => (
                                    <Grid item xs={12} sm={6} key={plan.id}>
                                        <SubscriptionCard name={plan.name} price={plan.price} description={plan.description} priceId={plan?.priceId} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    )}
                </Container>
            </Paper>
        </>
    );
};

export default SubscriptionsList;
