import { ThemeOptions } from '@mui/material/styles/createTheme'

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#0f0',
        },
        background: {
            default: '#333135',
            paper: '#212121',
        },
        secondary: {
            main: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Ubuntu Mono, Open Sans',
        h1: { fontFamily: 'Ubuntu Mono' },
        h2: { fontFamily: 'Ubuntu Mono' },
        h3: { fontFamily: 'Ubuntu Mono' },
        h4: { fontFamily: 'Ubuntu Mono' },
        h6: { fontFamily: 'Ubuntu Mono' },
        h5: { fontFamily: 'Ubuntu Mono' },
        subtitle1: { fontFamily: 'Ubuntu Mono' },
        subtitle2: { fontFamily: 'Ubuntu Mono' },
        button: {
            fontFamily: 'Ubuntu Mono',
            fontWeight: 700,
            lineHeight: 1.75,
        },
        overline: { fontFamily: 'Ubuntu Mono' }
    },
    shape: {
        borderRadius: 4,
    },
    spacing: 8,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                'input:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                    transitionDelay: '9999s',  // This ensures the background color remains consistent even after animation
                },
                'input:-webkit-autofill:hover': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                },
                'input:-webkit-autofill:focus': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                },
                'input:-webkit-autofill:active': {
                    WebkitBoxShadow: `0 0 0 1000px ${'#333135'} inset`,
                },
            }
        }
    },
};

export default darkThemeOptions;
