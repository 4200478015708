// AuthProvider.tsx
import React, { createContext, useEffect, useState, ReactNode } from 'react';
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    User,
    Auth,
    getAuth,
    UserCredential,
    GoogleAuthProvider,
    signInWithPopup,
    OAuthProvider,
} from 'firebase/auth';
import { RemoveDeviceResponse, SubscriptionResponse } from '../types/types';
import axios from 'axios';
// import { collection, getFirestore, onSnapshot, query, where, Timestamp, getDocs } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { FirebaseApp } from 'firebase/app';
import { initFirebase } from '../services/firebaseService';

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

interface AuthContextType {
    user: User | null;
    loading: boolean;
    createUser: (email: string, password: string) => Promise<UserCredential>;
    signInWithGoogle: () => Promise<UserCredential>;
    signInWithApple: () => Promise<UserCredential>;
    logOut: () => Promise<void>;
    getSubscriptionStatus: (app: FirebaseApp) => Promise<SubscriptionResponse>;
    removeRegisteredDevice: (app: FirebaseApp, deviceId: string) => Promise<RemoveDeviceResponse>;
    app: FirebaseApp;
    auth: Auth;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const app = initFirebase();
    const auth = getAuth(app);

    const createUser = async (email: string, password: string) => {
        setLoading(true);
        try {
            return await createUserWithEmailAndPassword(auth as Auth, email, password);
        } finally {
            setLoading(false);
        }
    };

    const signInWithGoogle = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, googleProvider);
            setUser(result.user);
            setLoading(false);
            return result;
        } finally {
            setLoading(false);
        }
    };

    const signInWithApple = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, appleProvider);
            setUser(result.user);
            setLoading(false);
            return result;
        } finally {
            setLoading(false);
        }
    };

    const logOut = async () => {
        setLoading(true);
        try {
            await signOut(auth as Auth);
        } finally {
            setLoading(false);
        }
    };

    const getSubscriptionStatus = async (app: FirebaseApp): Promise<SubscriptionResponse> => {
        const auth = getAuth(app);
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error('User not logged in');

        const response: any = await callFirebaseFunction({
            functionUrl: 'https://getsubscriptionusage-6rl3pxafdq-ey.a.run.app',
            user: auth.currentUser,
        });

        // console.log('getSubscription result ', JSON.stringify(response));
        return response as SubscriptionResponse;
    };

    const removeRegisteredDevice = async (app: FirebaseApp, deviceId: string) => {
        const auth = getAuth(app);
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error('User not logged in');
        const response: any = await callFirebaseFunction({
            functionUrl: 'https://removeregirtereddevice-6rl3pxafdq-ey.a.run.app',
            user: auth.currentUser,
            params: { device_id: deviceId },
        });
        // console.log('remove registered device result ', JSON.stringify(response));
        return response;
    };

    interface FirebaseFunctionParams {
        functionUrl: string;
        user: User;
        params?: any;
        body?: any;
        method?: string;
    }

    const callFirebaseFunction = ({ functionUrl, user, params = '', body = '', method = 'get' }: FirebaseFunctionParams) => {
        return new Promise((resolve, reject) => {
            user.getIdToken().then((token) => {
                let axiosConfig: any = {
                    method: method,
                    url: functionUrl,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                if (params) {
                    axiosConfig.params = params;
                }
                if (body) {
                    axiosConfig.data = body;
                }
                // console.log('axiosConfig', axiosConfig);
                axios(axiosConfig)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.error('Error calling firebase function', err);
                        reject(err);
                    });
            });
        });
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth as Auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, [auth]);

    const authValue = {
        user,
        loading,
        createUser,
        signInWithGoogle,
        signInWithApple,
        logOut,
        getSubscriptionStatus,
        removeRegisteredDevice,
        app,
        auth,
    };

    return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthProvider;
