// src/components/PrivateRoute.tsx
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { loading, user } = authContext || {}; // Add null check

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        textAlign={"center"}
      >
        {" "}
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      </Grid>
    );
  }

  return user ? <>{children}</> : <Navigate to="/" />;
};

export default PrivateRoute;
