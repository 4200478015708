import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { Download } from '@mui/icons-material';

const NavBar: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { logOut, loading, user } = authContext || {};

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Button component={RouterLink} to="/" sx={{ mr: 2 }}>
                            SonaScript
                        </Button>
                        <Button component={RouterLink} to="/download" sx={{ mr: 2 }}>
                            Download SonaScript
                        </Button>
                    </Box>
                    <Box>
                        {user && logOut ? (
                            <>
                                <Button component={RouterLink} to="/profile" sx={{ mr: 2 }}>
                                    My Profile
                                </Button>
                                <Button onClick={logOut}>Logout</Button>
                            </>
                        ) : (
                            <>
                                <Button component={RouterLink} to="/signup">
                                    Sign-In
                                </Button>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Box>
    );
};

export default NavBar;
