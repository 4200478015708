import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';
import SignInButton from './SignInButton';
import AppleIcon from '@mui/icons-material/Apple';

const SignInWithApple: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { signInWithApple } = authContext || {};

    return (
        <SignInButton
            label="Sign-In with Apple"
            onClick={signInWithApple!}
            icon={<AppleIcon />}
        />
    );
};

export default SignInWithApple;
