import React from "react";
import { Container, Typography, Box } from "@mui/material";

const Privacy: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Your privacy is important to us. It is SonaScript's policy to respect
          your privacy regarding any information we may collect from you across
          our website and other sites we own and operate.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we're collecting it
          and how it will be used.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Use of Information
        </Typography>
        <Typography variant="body1" paragraph>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we'll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorized access, disclosure, copying, use or
          modification.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. Third-Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          We don't share any personally identifying information publicly or with
          third-parties, except when required to by law. Our website may link to
          external sites that are not operated by us. Please be aware that we
          have no control over the content and practices of these sites, and
          cannot accept responsibility or liability for their respective privacy
          policies.
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;
