import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { Box, Container, Grid, Typography } from '@mui/material';
import { SubscriptionResponse } from '../types/types';
import Subscription from './SubscriptionComponent';
import SubscriptionsList from './SubscriptionsList';
import Skeleton from '@mui/material/Skeleton';

const Profile: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { user, getSubscriptionStatus, removeRegisteredDevice, app, loading } = authContext || {};
    const navigate = useNavigate();
    const [subscription, setSubscription] = React.useState<SubscriptionResponse>();
    const [loadingSubscription, setLoadingSubscription] = React.useState(true);
    const [shouldloadSubscription, setShouldLoadSubscription] = React.useState(true);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
        if (user && getSubscriptionStatus && app && shouldloadSubscription) {
            setLoadingSubscription(true);
            getSubscriptionStatus(app).then((subscription) => {
                // console.log('subscription:');
                // console.log(subscription);
                setSubscription(subscription);
                setLoadingSubscription(false);
            });
            setShouldLoadSubscription(false);
        }
    }, [user, navigate, shouldloadSubscription]);

    const handleDeleteDevice = async (deviceId: string) => {
        // console.log('Deleting device with id: ', deviceId);
        if (app && deviceId && removeRegisteredDevice) {
            setLoadingSubscription(true);
            removeRegisteredDevice(app, deviceId).then((result) => {
                // console.log('remove result');
                setShouldLoadSubscription(true);
            });
        }
    };

    return (
        <Container component="main">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={2} justifyContent={'center'} textAlign={'center'}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Welcome {user?.email}</Typography>
                    </Grid>

                    {!loading && !loadingSubscription ? (
                        <Grid item xs={12}>
                            {subscription && <Subscription subscription={subscription} onDeleteDevice={handleDeleteDevice} />}

                            {subscription && subscription.is_trial && (
                                <>
                                    <SubscriptionsList />
                                </>
                            )}
                        </Grid>
                    ) : (
                        <Box sx={{ width: 300 }}>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                        </Box>
                    )}
                </Grid>
            </Box>
        </Container>
    );
};

export default Profile;
