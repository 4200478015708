import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Terms: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to SonaScript. By using our services, you agree to these terms. Please read them carefully.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Use of Service
        </Typography>
        <Typography variant="body1" paragraph>
          You must follow any policies made available to you within the Services. Don't misuse our Services. For example, don't interfere with our Services or try to access them using a method other than the interface and the instructions that we provide.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          SonaScript's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that SonaScript can use such data in accordance with our privacy policies.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. Modifications
        </Typography>
        <Typography variant="body1" paragraph>
          We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly.
        </Typography>
      </Box>
    </Container>
  );
};

export default Terms;
