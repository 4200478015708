import React, { useState } from 'react';
import { Grid, Paper, Typography, useTheme, useMediaQuery, MobileStepper, Button, Box } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { SubscriptionResponse, Device } from '../types/types';
import ManageSubscription from './ManageSubscription';
import DeviceCard from './DeviceCard';

interface SubscriptionProps {
    subscription: SubscriptionResponse;
    onDeleteDevice: (deviceId: string) => Promise<void>;
}

const Subscription: React.FC<SubscriptionProps> = ({ subscription, onDeleteDevice }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeStep, setActiveStep] = useState(0);

    if (!subscription || !subscription.subscription_data) {
        return null;
    }

    const { device_count, device_limit, devices } = subscription;
    const cardsPerPage = isMobile ? 1 : 3;
    const maxSteps = Math.ceil(devices.length / cardsPerPage);

    const handleDeleteDevice = async (deviceId: string) => {
        try {
            await onDeleteDevice(deviceId);
            // If we're on the last page and delete the last item, go back one page
            if (activeStep === maxSteps - 1 && devices.length % cardsPerPage === 1) {
                setActiveStep(Math.max(0, activeStep - 1));
            }
        } catch (error) {
            console.error('Error deleting device:', error);
        }
    };

    const handleNext = () => {
        setActiveStep((prevStep) => Math.min(prevStep + 1, maxSteps - 1));
    };

    const handleBack = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const renderDevices = () => {
        const startIdx = activeStep * cardsPerPage;
        const currentDevices = devices.slice(startIdx, startIdx + cardsPerPage);

        return (
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        mb: 2,
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'center',
                    }}
                >
                    <Grid container spacing={2} justifyContent="center">
                        {currentDevices.map((device: Device) => (
                            <Grid
                                item
                                xs={12}
                                sm={isMobile ? 12 : 4}
                                key={device.device_id}
                                sx={{
                                    width: isMobile ? '100%' : '33.33%',
                                    maxWidth: isMobile ? '300px' : 'none',
                                }}
                            >
                                <DeviceCard device={device} onDelete={handleDeleteDevice} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{
                        maxWidth: isMobile ? 300 : 600,
                        flexGrow: 1,
                        backgroundColor: 'transparent',
                        mx: 'auto',
                    }}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            Next
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    }
                />
            </Box>
        );
    };

    return (
        <>
            {subscription && (
                <Grid item xs={12} container justifyContent="center">
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            my: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: isMobile ? '350px' : '1000px',
                        }}
                    >
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <ManageSubscription subscription={subscription} />
                            </Grid>
                            {devices.length > 0 && (
                                <>
                                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                                        <Typography sx={{ mb: 2 }}>
                                            Registered Devices {device_count}/{device_limit}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="center">
                                        {renderDevices()}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            )}
        </>
    );
};

export default Subscription;
