import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AuthRedirect: React.FC = () => {
    const location = useLocation();
    const [status, setStatus] = useState<'loading' | 'redirecting' | 'success' | 'error'>('loading');

    useEffect(() => {
        try {
            // Combine search and hash parameters
            const allParams = new URLSearchParams(location.search + location.hash.slice(1));

            // Convert parameters to an object
            const paramsObject = Object.fromEntries(allParams.entries());

            if (Object.keys(paramsObject).length > 0) {
                setStatus('success');

                // Create a query string from all parameters
                const queryString = new URLSearchParams(paramsObject).toString();

                // Wait a brief moment to show the success message, then redirect
                // setTimeout(() => {
                window.location.href = `sonascript://auth?${queryString}`;
                // }, 0);
            } else {
                setStatus('error');
                console.error('Authentication failed: No parameters received');
            }
        } catch (error) {
            setStatus('error');
            console.error('Authentication failed:', error);
        }
    }, [location]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '75vh',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
                textAlign: 'center',
                padding: '20px',
            }}
        >
            {status === 'loading' && <div>Initializing authentication...</div>}
            {status === 'success' && (
                <>
                    {/* <h2>Authentication Successful!</h2> */}
                    <p>Redirecting to Sonascript application...</p>
                    <p>You can close this window after redirection.</p>
                </>
            )}
            {status === 'error' && <div style={{ color: 'red' }}>Authentication failed. Please try again or contact support.</div>}
        </div>
    );
};

export default AuthRedirect;
